import { CustomerBuilder } from '@models/builders/customer.builder';
import { OrderResumeBuilder } from './../../models/builders/order-resume.builder';
import { OrderStatus } from '@models/enums/order-status.enum';
import { type FoGplItemsPerGroupsDto } from '@models/dto/fo/fo.gplItemsPerGroups.dto';
import { type RequestableFoGplReferencingGroupRelsDto } from '@models/dto/fo/fo.gplReferencingGroupRels.dto';
import { type RequestableFoProductsV2Dto } from '@models/dto/fo/fo.productsV2.dto';
import { type Article } from '@models/viewModels/article';
import { type Catalog } from '@models/viewModels/catalog/catalog';
import { type CatalogItem } from '@models/viewModels/catalog/catalog-item';
import { type RequestableFoCustomersV3Dto } from '@models/dto/fo/fo.customersV3.dto';
import { type ResquestableFoEmployeesV2Dto } from '@models/dto/fo/fo.employeesV2.dto';
import { type User } from '@models/viewModels/user';
import { type Customer } from '@models/viewModels/customer';
import { type Contact } from '@models/viewModels/contact';
import { type Person } from '@models/viewModels/person';
import { FoSalesOrderHeadersV2FullDto, FoSalesOrderHeaderResumeDto, type RequestableFoSalesOrderHeaderDto } from '@models/dto/fo/fo.salesOrderHeadersV2.dto';
import { type RequestableFoSplInventParametersDto } from '@models/dto/fo/fo.splInventParameter.dto';
import { FoSalesOrderLinesFullDto, type RequestableFoSalesOrderLinesDto } from '@models/dto/fo/fo.salesOrderLines.dto';
import { type RequestableFoProductSpecificOrderSettingsV2Dto } from '../../models/dto/fo/fo.productSpecificOrderSettingV2.dto';
import { type OrderHeader } from '@models/viewModels/order/order-header';
import { type OrderLine } from '@models/viewModels/order/order-line';
import { type Order } from '@models/viewModels/order/order';
import { RequestableSPLFoHierarchyCategoryReferencingsDto } from '@app/core/models/dto/fo/fo.splHierarchyCategoryReferencings.dto';
import { FoSalesStatus } from '@app/core/models/dto/fo/fo-enum/fo-sales-line-status';
import { FoSPLogilabSalesStatus } from '@app/core/models/dto/fo/fo-enum/fo-logilab-sales-status.enum';
import { OrderError } from '@app/core/models/viewModels/order/order-error';
import { RequestableFoSPLLogiLabHistoryErrorsDto } from '@app/core/models/dto/fo/fo.splLogilabHistoryErrors.dto';
import { ProductVariantNumberType, RequestableFoReleasedProductVariantsV2Dto, VariantCompleteRef } from '@app/core/models/dto/fo/fo.ReleasedProductVariantsV2.dto';
import { OrderResume } from '@app/core/models/viewModels/order/order-resume';
import { RequestableFoSPLSalesOrderHistoryDto } from '@app/core/models/dto/fo/fo.splSalesOrderHistory.dto';
import { OrderHistory } from '@app/core/models/viewModels/order/order-history';
import { CatalogResponseItem } from '@app/core/models/dto/fo/fo.catlogItemBoundActions/fo.catalog-response';
import { LineStatus } from '@app/core/models/enums/line-status';
import { RequestableFoSplDirPartyEcoResCategoriesDto } from '@app/core/models/dto/fo/fo.splDirPartyEcoResCategories.dto';
import { DateValidator } from '@app/shared/utils/validators/date.validator';
import { ArticleBuilder } from '@app/core/models/builders/article.builder';
import { OrderBuilder } from '@app/core/models/builders/order.builder';
import { OrderHeaderBuilder } from '@app/core/models/builders/order-header.builder';
import { OrderlineBuilder } from '@app/core/models/builders/order-line.builder';
import { ContactBuilder } from '@app/core/models/builders/contact.builder';
import { PersonBuilder } from '@app/core/models/builders/person.builder';
import { UserBuilder } from '@app/core/models/builders/user.builder';
import { CatalogBuilder } from '@app/core/models/builders/catalog.builder';
import { CatalogItemBuilder } from '@app/core/models/builders/catalogItem.builder';
import { RequestableFoEcoResProductTranslationDtoFields } from '@app/core/models/dto/fo/fo.EcoResProductTranslationBIEntities.dto';
import { environment } from '@environments/environment';
import { AppImage } from '@app/core/models/viewModels/image';

// #region DefaultValue

export function generateDefaultValue<T>(): T {
  return { ... {} as T };
}


export function getDefaultContact(): Contact {
  return new ContactBuilder().build();
}

export function getDefaultPerson(): Person {
  return new PersonBuilder().build();
}

export function getDefaultCustomer(): Customer {
  return new CustomerBuilder().build();
}

export function getDefaultUser(): User {
  return new UserBuilder().build();
}

export function getDefaultArticle(): Article {
  return new ArticleBuilder().build();
}

export function getDefaultCatalog(): Catalog {
  return new CatalogBuilder().build();
}

export function getDefaultCatalogItem(): CatalogItem {
  return new CatalogItemBuilder().build();
}

export function getDefaultOrderHeader(): OrderHeader {
  return new OrderHeaderBuilder().build();
}
export function getDefaultOrderResume(): OrderResume {
  return new OrderResumeBuilder().build();
}
export function getDefaultOrderLine(): OrderLine {
  return new OrderlineBuilder().build();
}

export function getDefaultOrder(): Order {
  return new OrderBuilder().build();
}

function debug(msg:string, obj: unknown | undefined = undefined) {
  if (environment.enableMappingDebug) {
    console.log(msg, obj);
  }
}

// #region UserGateway

export function mapEmployeeDtoToUser(usertoMap: User, dto: ResquestableFoEmployeesV2Dto): User {
  debug('MAPPING DEBUG : mapEmployeeDtoToUser dto :', dto);
  usertoMap.person.ref = dto.PartyNumber;
  usertoMap.person.contact.mail = dto.PrimaryContactEmail;
  usertoMap.registerNumber = dto.PersonnelNumber;
  usertoMap.defaultDataAreaId = dto.EmploymentLegalEntityId;
  usertoMap.logisticEmployeeId = dto.SPLRecId;
  usertoMap.language = dto.LanguageId;

  debug('MAPPING DEBUG : mapEmployeeDtoToUser usertoMap after up:', usertoMap);
  return usertoMap;
}
/**
 * Generates a Customer object from the provided RequestableFoCustomersV3Dto object.
 * Initializes the Customer with default values using the generateDefaultValue function.
 * Sets the catalog details, person details, and contact details based on the data from the dto.
 * Logs the generated customer object before returning.
 *
 * @param dto - The RequestableFoCustomersV3Dto object containing customer data.
 * @returns A Customer object created from the dto data.
 */
export function generateCustomerFromFoCustomerV3Dto(dto: RequestableFoCustomersV3Dto): Customer {
  const customer = getDefaultCustomer();

  const catalog = generateDefaultValue<Catalog>();
  catalog.nodeId = dto.GPLNodeId;

  const person = generateDefaultValue<Person>();
  person.firstName = dto.PersonFirstName;
  person.lastName = dto.PersonLastName;
  person.ref = dto.ContactPersonId;

  const contact = generateDefaultValue<Contact>();
  contact.mail = dto.PrimaryContactEmail;
  contact.phone = dto.PrimaryContactPhone;
  person.contact = contact;

  customer.catalog = catalog;
  customer.contactPerson = person;
  customer.number = dto.CustomerAccount;
  customer.dataAreaId = dto.dataAreaId;
  debug('MAPPING DEBUG : customer', customer);
  return customer;
}
/**
 * Generates a Customer object from the provided RequestableFoSplDirPartyEcoResCategoriesDto.
 *
 * @param dto - The data transfer object containing information to create the Customer.
 * @returns The Customer object created from the dto.
 */
export function generateCustomerFromSplDirPartyEcoResCategaory(dto: RequestableFoSplDirPartyEcoResCategoriesDto): Customer {
  const customer = getDefaultCustomer();

  const catalog = generateDefaultValue<Catalog>();
  catalog.nodeId = dto.GPLNodeId;

  const person = generateDefaultValue<Person>();
  person.firstName = dto.PersonFirstName;
  person.lastName = dto.PersonLastName;
  person.ref = dto.ContactPersonId;

  const contact = generateDefaultValue<Contact>();
  contact.mail = dto.PrimaryContactEmail;
  contact.phone = dto.PrimaryContactPhone;
  person.contact = contact;

  customer.catalog = catalog;
  customer.contactPerson = person;
  customer.number = dto.AccountNum;
  customer.dataAreaId = dto.dataAreaId;

  return customer;
}
// #endregion

// #region Article and referncing
export function generateArticleFromProductDto(dto: RequestableFoProductsV2Dto): Article {
  const article = getDefaultArticle(); // generate all fields
  /* const article = generateDefaultValue<Article>();// generate only setted field  */
  article.code = dto.ProductNumber;
  article.name = dto.ProductName;
  article.details = dto.ProductDescription;
  article.category = dto.ProductSearchName;
  return article;
}

export function mapVariantInfoToArticle(article: Article, dto: RequestableFoReleasedProductVariantsV2Dto): Article {
  debug('MAPPING DEBUG : mapVariantInfoToArticle dto :', dto);
  debug('MAPPING DEBUG : mapVariantInfoToArticle article before :', article);

  article.color = dto.ProductColorId;
  article.size = dto.ProductSizeId;
  article.style = dto.ProductStyleId;
  article.config = dto.ProductConfigurationId;
  article.details = dto.ProductDescription;
  article.name = dto.ProductName;

  article.variantRef = generateRefByProductVariantNumber(dto.ProductVariantNumber);

  debug('MAPPING DEBUG : mapVariantInfoToArticle article after:', article);
  return article;
}

export function mapTranslationToArticle(article: Article, dto: RequestableFoEcoResProductTranslationDtoFields) {
  debug('MAPPING DEBUG : mapVariantInfoToArticle dto :', dto);
  article.name = dto.Name || `${article.name} *`;
  article.details = dto.Description || `${article.details} *`;
  debug('MAPPING DEBUG : mapVariantInfoToArticle article before :', article);
}

/**
 * Génère une référence à partir d'un numéro de variante de produit.
 * @param {ProductVariantNumberType} productVariantNumber - Le numéro de variante du produit.
 * @returns {string} La référence générée.
 */
export function generateRefByProductVariantNumber(productVariantNumber: ProductVariantNumberType): string {
  /* productVariantNumberType a pour masque 'ItemId:Config:Size:Color:Style' */
  debug('MAPPING DEBUG : generateRefByProductVariantNumber productVariantNumber :', productVariantNumber);
  const code = productVariantNumber.split(':')                                           // Divise la chaîne en segments
    .slice(1)                                             // Retire le segment ItemId
    .map(segment => segment ? segment.trim() : '')        // Vérifie si segment est défini avant d'appeler trim() => Supprime les espaces avant et après chaque segment
    .filter(segment => segment !== '')                    // Retire les segments vides
    .join('-');                                           // Joint les segments restants avec des tirets
  debug('MAPPING DEBUG : generateRefByProductVariantNumber code ', code);
  return code;
}

export function getArticleVariantRef(article: Article) {
  let ref = `${article.code}`;
  ref = addRef(ref, article.config);
  ref = addRef(ref, article.size);
  ref = addRef(ref, article.color);
  ref = addRef(ref, article.style);
  return ref;
}
export function addRef(build: string, value: string) {
  if (value != "") build += ` : ${value}`;
  return build;
}

export function buildVariantRefType(itemid: string, config: string, size: string, color: string, style: string): ProductVariantNumberType {
  return `${itemid}:${config}:${size}:${color}:${style}`;
}
export function buildItemVariantRef(config: string, size: string, color: string, style: string): string {
  return [config, size, color, style]
    .map(segment => segment ? segment.trim() : '')
    .filter(segment => segment !== '')
    .join('-');
}

export function generateCatalogFromReferencingGroupDto(dto: RequestableFoGplReferencingGroupRelsDto): Catalog {
  return {
    description: dto.RefGroupChildId,
    dataAreaId: dto.dataAreaId,
    referenceName: dto.RefGroupChildId,
    items: [],
    nodeId: dto.NodeId
  };
}
export function generateCatalogFromSPLHierarchyCategoryReferencingsDto(dto: RequestableSPLFoHierarchyCategoryReferencingsDto): Catalog {
  return {
    description: dto.Description,
    dataAreaId: dto.dataAreaId,
    referenceName: dto.RefGroupId,
    items: [],
    nodeId: 0
  };
}

export function generateItemsFromItemPerGroupsDto(dto: FoGplItemsPerGroupsDto): CatalogItem {
  const article = getDefaultArticle();
  const catalogItem = generateDefaultValue<CatalogItem>();
  article.code = dto.ItemId;
  catalogItem.article = article;
  catalogItem.minOrderingQuantity = dto.Qty;
  catalogItem.maxOrderingQuantity = dto.SPLBlockingQty;
  catalogItem.warnOrderingQuantity = dto.SPLAlertQty;
  return catalogItem;
}

export function mapProductSpecificOrderSettingDtoToArticle(article: Article, dto: RequestableFoProductSpecificOrderSettingsV2Dto): void {
  article.packingQuantity = (dto.SalesQuantityMultiples > 1) ? dto.SalesQuantityMultiples : 1;

}

export function updateArticleInCatalogItems(catalogItems: CatalogItem[], articles: Article[]): void {
  articles.forEach((article) => {
    catalogItems.forEach(catalogItem => {
      if (catalogItem.article.code === article.code) {
        catalogItem.article = article;
      }
    });
  });
}
export function generateCatalogItem(catalogResponseItem: CatalogResponseItem): CatalogItem {
  const ref: VariantCompleteRef = {
    ItemNumber: catalogResponseItem.ItemId,
    ProductConfigurationId: catalogResponseItem.ConfigId,
    ProductSizeId: catalogResponseItem.InventSizeId,
    ProductColorId: catalogResponseItem.InventColorId,
    ProductStyleId: catalogResponseItem.InventStyleId
  };

  const article = getDefaultArticle();
  article.code = catalogResponseItem.ItemId;
  article.style = catalogResponseItem.InventStyleId;
  article.size = catalogResponseItem.InventSizeId;
  article.color = catalogResponseItem.InventColorId;
  article.config = catalogResponseItem.ConfigId;
  article.name = catalogResponseItem.Name;
  article.details = catalogResponseItem.Detail;
  article.hasBom = catalogResponseItem.HasBom;


  const img = generateDefaultValue<AppImage>();
  img.base64 = catalogResponseItem.ReturnDefaultImage;
  img.src = catalogResponseItem.ReturnDefaultImageURL;
  img.alt = catalogResponseItem.Name;
  img.thumbnail = catalogResponseItem.ReturnDefaultImageURLThumbnail;
  article.img=img;


  article.category = catalogResponseItem.SearchName;

  article.packingQuantity = (catalogResponseItem.QtyMultiples != undefined && catalogResponseItem.QtyMultiples > 0) ? catalogResponseItem.QtyMultiples : 1;
  article.variantRef = generateRefByVariantRef(ref);

  const item = generateDefaultValue<CatalogItem>();
  item.article = { ...article };
  item.maxOrderingQuantity = catalogResponseItem.BlockingQty;
  item.minOrderingQuantity = catalogResponseItem.Qty;
  item.warnOrderingQuantity = catalogResponseItem.AlertQty;
  item.stock = catalogResponseItem.AvailPhysical;
  item.isStokedProduct = catalogResponseItem.StockedProduct == 'Yes';

  return item;
}

export function generateRefByVariantRef(variantref: VariantCompleteRef) {
  return `${variantref.ItemNumber}:${variantref.ProductConfigurationId}:${variantref.ProductSizeId}:${variantref.ProductColorId}:${variantref.ProductStyleId}`;
}

export function mapFoCatalogResponseItemToCatalogItem(dto: CatalogResponseItem) {
  const article = generateDefaultValue<Article>();
  const catalogItem = generateDefaultValue<CatalogItem>();
  article.code = dto.ItemId;
  article.name = dto.Name;
  article.color = dto.InventColorId;
  article.size = dto.InventSizeId;
  article.style = dto.InventStyleId;
  article.config = dto.ConfigId;
  const img = generateDefaultValue<AppImage>();
  img.base64 = dto.ReturnDefaultImage;
  img.src = dto.ReturnDefaultImageURL;
  img.alt = dto.Name;
  img.thumbnail = "";
  article.img=img;

  article.img.base64 = dto.ReturnDefaultImage;
  article.img.src = dto.ReturnDefaultImageURL;
  article.img.alt = dto.Name;
  article.img.thumbnail= dto.ReturnDefaultImageURLThumbnail;
  /*[] need to recalculate*/
  article.packingQuantity = (dto.QtyMultiples != undefined && dto.QtyMultiples > 0) ? dto.QtyMultiples : 1;
  article.details = dto.Detail;
  article.category = dto.SearchName;
  article.variantRef = buildItemVariantRef(dto.ConfigId, dto.InventSizeId, dto.InventColorId, dto.InventStyleId);

  catalogItem.article = article;

  catalogItem.minOrderingQuantity = dto.Qty;
  catalogItem.warnOrderingQuantity = dto.AlertQty;
  catalogItem.maxOrderingQuantity = dto.BlockingQty;
  catalogItem.stock = dto.AvailPhysical;
  catalogItem.isStokedProduct = dto.StockedProduct == 'Yes';

  return catalogItem;
}

// #endregion

// #region OrderGateway

export function generateOrderHeaderFromFoSoHeaderDto(dtoValue: RequestableFoSalesOrderHeaderDto | FoSalesOrderHeadersV2FullDto): OrderHeader {
  const orderHeader: OrderHeader = getDefaultOrderHeader();
  orderHeader.orderNumber = dtoValue.SalesOrderNumber;
  orderHeader.dataAreaId = dtoValue.dataAreaId;
  orderHeader.customerNumber = dtoValue.OrderingCustomerAccountNumber;
  orderHeader.deliveryAddress = dtoValue.FormattedDelveryAddress;
  orderHeader.deliveryDate = new Date(dtoValue.SPLShippingDateMax);
  orderHeader.memo = dtoValue.GPLExternalMemo;
  orderHeader.orderStatus = mapFoSalesStatusToOrderStatus(parseFoLogilabStatusFromString(dtoValue.SPLSalesOrderLogilabStatus));
  orderHeader.orderDate = new Date(dtoValue.SPLOrderDate);
  orderHeader.customerFullName = dtoValue.SalesOrderName;
  orderHeader.deliveryMode = dtoValue.DeliveryModeCode;
  orderHeader.haveErrorIntegration = dtoValue.SPLIntegrationError == 'Yes' ? true : false;
  orderHeader.assignment = dtoValue.SPLOperatorWorker;
  orderHeader.intercoShippingDate = new Date(dtoValue.SPLShipmentProcessIntercoDate);

  return orderHeader;
}
export function generateOrderHeaderResume(dtoValue: FoSalesOrderHeaderResumeDto): OrderResume {
  const orderResume: OrderResume = getDefaultOrderResume();
  orderResume.orderStatus = mapFoSalesStatusToOrderStatus(parseFoLogilabStatusFromString(dtoValue.SPLSalesOrderLogilabStatus));
  orderResume.haveErrorIntegration = dtoValue.SPLIntegrationError == 'Yes' ? true : false;
  orderResume.dataAreaId = dtoValue.dataAreaId;
  orderResume.orderNumber = dtoValue.SalesOrderNumber;
  orderResume.haveInterco = DateValidator.isNotNullAndNotADefaultDate(new Date(dtoValue.SPLShipmentProcessIntercoDate)); return orderResume;
}


export function generateOrderLinesFromFoSoLinesDto(dtoValue: RequestableFoSalesOrderLinesDto | FoSalesOrderLinesFullDto, article: Article): OrderLine {
  debug('MAPPING DEBUG : generateOrderLinesFromFoSoLinesDto dtoValue :', dtoValue);
  const embededArticle: Article = { ...article };
  const variantRef: ProductVariantNumberType = `${dtoValue.ItemNumber}:${dtoValue.ProductConfigurationId}:${dtoValue.ProductSizeId}:${dtoValue.ProductColorId}:${dtoValue.ProductStyleId}`;

  embededArticle.config = dtoValue.ProductConfigurationId;
  embededArticle.style = dtoValue.ProductStyleId;
  embededArticle.size = dtoValue.ProductSizeId;
  embededArticle.color = dtoValue.ProductColorId;
  embededArticle.variantRef = generateRefByProductVariantNumber(variantRef);
  debug('MAPPING DEBUG : generateOrderLinesFromFoSoLinesDto embededArticle.variantRef :', embededArticle.variantRef);
  embededArticle.code = dtoValue.ItemNumber;
  debug('MAPPING DEBUG : generateOrderLinesFromFoSoLinesDto embededArticle :', embededArticle);

  const orderLine: OrderLine = getDefaultOrderLine();
  orderLine.article = embededArticle;
  orderLine.description = dtoValue.LineDescription;
  orderLine.dataAreaId = dtoValue.dataAreaId;
  orderLine.lineStatus = mapFoSalesStatusToOrderSalesStatus(parseFoStatusFromString(dtoValue.SalesOrderLineStatus));
  orderLine.lineTotal = dtoValue.OrderedSalesQuantity;
  orderLine.orderNumber = dtoValue.SalesOrderNumber;
  orderLine.quantity = dtoValue.OrderedSalesQuantity / embededArticle.packingQuantity;
  orderLine.id = dtoValue.InventoryLotId;
  orderLine.masterid = dtoValue.SPLSourceSalesLine;
  orderLine.ismaster = dtoValue.SPLRecId === dtoValue.SPLSourceSalesLine;

  return orderLine;
}

export function mapFoSalesStatusToOrderSalesStatus(foSalesStatus: FoSalesStatus): LineStatus {
  switch (foSalesStatus) {
    case FoSalesStatus.Backorder:
      return LineStatus.IN_PROGRESS;

    case FoSalesStatus.Invoiced:
      return LineStatus.SENT;

    case FoSalesStatus.Delivered:
      return LineStatus.SENT;

    case FoSalesStatus.Canceled:
      return LineStatus.CANCELED;

    default:
      return LineStatus.UNKNOWN;

  }
}


export function parseFoStatusFromString(statusString: string): FoSalesStatus {
  switch (statusString) {
    case 'Backorder':
      return FoSalesStatus.Backorder;

    case 'Invoiced':
      return FoSalesStatus.Invoiced;

    case 'Delivered':
      return FoSalesStatus.Delivered;

    case 'Canceled':
      return FoSalesStatus.Canceled;

    case 'BillSchPartialInvoiced':
      return FoSalesStatus.BillSchPartialInvoiced;

    case 'None':
      return FoSalesStatus.None;

    default:
      return FoSalesStatus.None;
  }
}


export function mapFoSPLInventParamsToOrderHeaderV2(header: RequestableFoSalesOrderHeaderDto, foSPLInventParams: RequestableFoSplInventParametersDto): RequestableFoSalesOrderHeaderDto {
  header.DefaultShippingSiteId = foSPLInventParams.SPLInventSiteId;
  header.DefaultShippingWarehouseId = foSPLInventParams.SPLInventLocationId;
  return header;
}

export function mapOrderStatusToFoSalesStatus(orderStatus: OrderStatus): FoSPLogilabSalesStatus {
  switch (orderStatus) {
    case OrderStatus.OPEN:
      return FoSPLogilabSalesStatus.OrderCreated;

    case OrderStatus.PRE_PROCESSING:
      return FoSPLogilabSalesStatus.PendingProcessing;

    case OrderStatus.IN_PROCESSING:
      return FoSPLogilabSalesStatus.InPreparation;

    case OrderStatus.AWAITING_SHIPMENT:
      return FoSPLogilabSalesStatus.PendingShipment;

    case OrderStatus.SENT:
      return FoSPLogilabSalesStatus.Shipped;

    case OrderStatus.CANCELED:
      return FoSPLogilabSalesStatus.Canceled;

    default:
      throw new Error(`Unknown OrderStatus: ${orderStatus}`);
  }
}

export function mapFoSalesStatusToOrderStatus(foSalesStatus: FoSPLogilabSalesStatus): OrderStatus {
  switch (foSalesStatus) {
    case FoSPLogilabSalesStatus.OrderCreated:
      return OrderStatus.OPEN;

    case FoSPLogilabSalesStatus.PendingProcessing:
      return OrderStatus.PRE_PROCESSING;

    case FoSPLogilabSalesStatus.InPreparation:
      return OrderStatus.IN_PROCESSING;

    case FoSPLogilabSalesStatus.PendingShipment:
      return OrderStatus.AWAITING_SHIPMENT;

    case FoSPLogilabSalesStatus.Shipped:
      return OrderStatus.SENT;

    case FoSPLogilabSalesStatus.Canceled:
      return OrderStatus.CANCELED;
    default:
      return OrderStatus.UNKNOWN;
  }
}

export function parseFoLogilabStatusFromString(statusString: string): FoSPLogilabSalesStatus {
  switch (statusString.toLowerCase()) {
    case 'ordercreated':
      return FoSPLogilabSalesStatus.OrderCreated;

    case 'pendingprocessing':
      return FoSPLogilabSalesStatus.PendingProcessing;

    case 'inpreparation':
      return FoSPLogilabSalesStatus.InPreparation;

    case 'shipped':
      return FoSPLogilabSalesStatus.Shipped;

    case 'pendingshipment':
      return FoSPLogilabSalesStatus.PendingShipment;

    case 'canceled':
      return FoSPLogilabSalesStatus.Canceled;

    default:

      debug(`MAPPING DEBUG : parseFoLogilabStatusFromString case Unknown ${statusString}`);
      return FoSPLogilabSalesStatus.OrderCreated;
  }
}

export function generateOrderErrorFromSPLLogiLabHistoryErrorsDto(dto: RequestableFoSPLLogiLabHistoryErrorsDto): OrderError {
  const error = generateDefaultValue<OrderError>();

  error.orderNumber = dto.SalesId;
  error.message = dto.Message;
  error.status = dto.Status;
  error.date = new Date(dto.CreatedDateTimeHistory);
  error.dataArea = dto.DataAreaIdHistory;
  error.ref = dto.SalesIdInterco;
  error.context = dto.Action;

  return error;
}

export function generateOrderHistoryFromSPLSalesOrderHistoryDto(dto: RequestableFoSPLSalesOrderHistoryDto): OrderHistory {
  const history = generateDefaultValue<OrderHistory>();

  history.dataAreaId = dto.dataAreaId;
  history.salesId = dto.SalesId;
  history.action = dto.Action;
  history.createdDateTimeHistory = new Date(dto.CreatedDateTimeHistory);
  history.createdByHistory = dto.CreatedByHistory;
  history.status = dto.Status;
  history.salesIdInterco = dto.SalesIdInterco;
  history.message = dto.Message;

  return history;
}



////#endregion
