import { type Article } from '@app/core/models/viewModels/article';
import { Basebuilder } from '@models/builders/basebuilder';
import { AppImage } from '../viewModels/image';
import { ImageBuilder } from './image.builder';

export class ArticleBuilder extends Basebuilder<Article> {
  private code: string = '';
  private name: string = '';
  private details: string = '';
  private packingQuantity: number = 1;
  private img: AppImage = new ImageBuilder().build() ;
  private imgUrl: string = '';
  private category: string = '';
  private config: string = '';
  private color: string = '';
  private size: string = '';
  private style: string = '';
  private variantName: string = '';
  private hasBom: boolean = false;

  public withCode(code: string): this { this.code = code; return this; }
  public withName(name: string): this { this.name = name; return this; }
  public withDetails(details: string): this { this.details = details; return this; }
  public withPackagingQuantity(quantity: number): this { this.packingQuantity = quantity; return this; }
  public withImg(img: AppImage): this { this.img = img; return this; }
  public withImgUrl(imgUrl: string): this { this.imgUrl = imgUrl; return this; }
  public withCategory(category: string): this { this.category = category; return this; }
  public withConfig(config: string): this { this.config = config; return this; }
  public withColor(color: string): this { this.color = color; return this; }
  public withSize(size: string): this { this.size = size; return this; }
  public withStyle(style: string): this { this.style = style; return this; }
  public withVariantName(variantName: string): this { this.variantName = variantName; return this; }
  public withBom(): this { this.hasBom = true; return this; }

  public build(): Article {
    return {
      code: this.code
      , name: this.name
      , details: this.details
      , packingQuantity: this.packingQuantity
      , img: this.img
      , category: this.category
      , config: this.config
      , color: this.color
      , size: this.size
      , style: this.style
      , variantRef: this.variantName
      , hasBom: this.hasBom
    };
  }
}
