import { Injectable } from '@angular/core';
import { type Observable, of, switchMap } from 'rxjs';
import { FoBaseClientApiService } from '@services/finops/fo-base.service';
import { StringValidator } from '@app/shared/utils/validators/string.validator';
import { type ODataArrayResult } from '@app/core/models/dto/odata-result';
import { D365Entities } from '@models/dto/D365Entities.enum';
import { type RequestableFoCustomersV3Dto, RequestableFoCustomersV3DtoFields } from '@app/core/models/dto/fo/fo.customersV3.dto';

@Injectable({
  providedIn: 'root'
})
export class FoCustomerService extends FoBaseClientApiService<RequestableFoCustomersV3Dto> {
  protected override getMainEntityName(): string {
    return D365Entities.CustomerV3;
  }
  /**
   *  Get some customers : base method
   * @param selectFields
   * @param filterParams
   * @param orderBy
   * @param expand
   * @param crossCompany
   * @returns ODataResult<RequestableFoCustomersV3Dto>
   */
  getSomeCustomers$ (selectFields: string[], filterParams: unknown, orderBy?: string[], expand?: string[], crossCompany: boolean = false): Observable<ODataArrayResult<RequestableFoCustomersV3Dto>> {
    return this.foODataService.getSomeWithFilterParams<RequestableFoCustomersV3Dto>(this.getMainEntityName(), selectFields, filterParams, orderBy, expand, crossCompany);
  }

  /**
   * Get some customers by party number
   * @param partyNumber
   * @returns
   */
  getSomeCustomersByParty$ (partyNumber: string): Observable<ODataArrayResult<RequestableFoCustomersV3Dto>> {
    if (StringValidator.isNullOrEmptyString(partyNumber)) return of(this.defaultOdataResult);
    const filterParams = [{ PartyNumber: `${partyNumber}` }];
    try {
      return this.getSomeCustomers$(
        RequestableFoCustomersV3DtoFields
        , filterParams, undefined, undefined, true);
    } catch (error) {
      this.logger.displayObjectError(error, 'Error getting Customer by PartyNumber and company :');
      throw error;
    }
  }

  /**
   * Get some customers by clientId
   * @param clientId
   * @param dataAreaId
   * @returns Observable
   * @usedIn getArticlesByCustomerNumberAndDataAreaId
   */
  getSomeCustomersByClientIdAndDataAreaId$ (clientId: string, dataAreaId: string): Observable<ODataArrayResult<RequestableFoCustomersV3Dto>> {
    if (StringValidator.isNullOrEmptyString(clientId) || StringValidator.isNullOrEmptyString(dataAreaId)) return of(this.defaultOdataResult);
    const filterParams = [{ CustomerAccount: `${clientId}` }, { dataAreaId: `${dataAreaId}` }];
    try {
      return this.getSomeCustomers$(
        RequestableFoCustomersV3DtoFields
        , filterParams, undefined, undefined, true);
    } catch (error) {
      this.logger.displayObjectError(error,'Error getting Customer by clientId and dataAreaId :');
      throw error;
    }
  }
  /**
   * Get some customers by company and party number
   * @param company
   * @param partyNumber
   * @returns
   */
  getSomeCustomersByCompanyAndParty$ (company: string, partyNumber: string): Observable<ODataArrayResult<RequestableFoCustomersV3Dto>> {
    if (StringValidator.isNullOrEmptyString(partyNumber)) return of(this.defaultOdataResult);
    const filterParams = [{ PartyNumber: `${partyNumber}` }, { dataAreaId: `${company}` }];
    try {
      return this.getSomeCustomers$(
        RequestableFoCustomersV3DtoFields
        , filterParams, undefined, undefined, true);
    } catch (error) {
      this.logger.displayObjectError(error,'Error getting Customer by PartyNumber and company :' );
      throw error;
    }
  }

    /**
     * Retrieves some customers based on the provided email.
     *
     * @param {string} email - The email to search for customers.
     * @return {Observable<ODataArrayResult<RequestableFoCustomersV3Dto>>} An observable that emits an ODataResult containing the requested customers.
     * @throws {Error} If an error occurs while retrieving the customers.
     */
  getsomeCustomersByEmail$ (email: string): Observable<ODataArrayResult<RequestableFoCustomersV3Dto>> {
    if (StringValidator.isNullOrEmptyString(email)) of(this.defaultOdataResult);

    const filterParams = [{ Email: `${email}` }];
    try {
      return this.getSomeCustomers$(
        RequestableFoCustomersV3DtoFields
        , filterParams);
    } catch (error) {
      this.logger.displayObjectError(error,'Error getting Customer by email:');
      throw error;
    }
  }


  getDeliveryMode$ (customerNumber: string, dataAreaId: string): Observable<string> {
    // DeliveryMode

    return this.getSomeCustomersByClientIdAndDataAreaId$( customerNumber, dataAreaId ).pipe(
      switchMap(
        (result: ODataArrayResult<RequestableFoCustomersV3Dto>) => {
        if (result.value.length > 0) {
          this.logger.displayObjectDebug(result,'customers found:');
          return of(result.value[0].DeliveryMode);
        }
        else {
          this.logger.displayObjectError(result,'no customers found. result ');
          return of(""); // return empty string
        }
      })

    );
  }

}
