import { AppImage } from "@app/core/models/viewModels/image";
import { Basebuilder } from "./basebuilder";

export class ImageBuilder extends Basebuilder<AppImage>{
  private src: string = '';
  private alt: string = '';
  private base64String: string = '';
  private thumbnail: string = '';

  /**
   * Set the src attribute of the image.
   * This is the URL of the image.
   * @param src The URL of the image.
   * @returns The current ImageBuilder.
   */
  withSrc(src: string): this {
    this.src = src;
    return this;
  }

  withAlt(alt: string): this {
    this.alt = alt;
    return this;
  }
  withBase64String(base64String: string): this {
    this.base64String = base64String;
    return this;
  }
  withThumbnail(thumbnail: string): this {
    this.thumbnail = thumbnail;
    return this;
  }

  override build(): AppImage {
    return {
      base64: this.base64String,
      src: this.src,
      thumbnail: this.thumbnail,
      alt: this.alt
    };
  }
}
